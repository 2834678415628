<template>
  <div class="presupuestador-menu">
    <div class="menu-progreso">
      <div class="numero" :class="{ 'numero-con-relleno': section >= 1 }">
        1
      </div>
      <div class="linea" :class="{ 'linea-con-relleno': section >= 2 }"></div>
      <div class="numero" :class="{ 'numero-con-relleno': section >= 2 }">
        2
      </div>
      <div class="linea" :class="{ 'linea-con-relleno': section >= 3 }"></div>
      <div class="numero" :class="{ 'numero-con-relleno': section >= 3 }">
        3
      </div>
      <div class="linea" :class="{ 'linea-con-relleno': section >= 4 }"></div>
      <div class="numero" :class="{ 'numero-con-relleno': section >= 4 }">
        4
      </div>
      <div class="linea" :class="{ 'linea-con-relleno': section >= 5 }"></div>
      <div class="numero" :class="{ 'numero-con-relleno': section >= 5 }">
        5
      </div>
      <div class="linea" :class="{ 'linea-con-relleno': section >= 6 }"></div>
      <div class="numero" :class="{ 'numero-con-relleno': section >= 6 }">
        6
      </div>
      <div class="linea" :class="{ 'linea-con-relleno': section >= 7 }"></div>
      <div class="numero" :class="{ 'numero-con-relleno': section >= 7 }">
        7
      </div>
    </div>
    <div class="budget">
      <form>
        <div v-if="section!==8" class="budget-form" >
          <!-- Sección de Ingresos -->
          <div v-if="section == 1" class="section-form">
            <h2>Ingresos Mensuales</h2>
            <div>
              <div>
                <label for="ingresos">Salarios:</label>
                <input
                  :value="ingresos"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    ingresos = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalIngresos"
                  required
                />
              </div>
              <div>
                <label for="otrosIngresos">Otros Ingresos:</label>
                <input
                  :value="otrosIngresos"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    otrosIngresos = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalIngresos"
                />
              </div>
            </div>
          </div>
          <!-- Sección de Gastos de Vivienda -->
          <div v-if="section == 2" class="section-form">
            <h2>Gastos de Vivienda</h2>
            <div>
              <div>
                <label for="hipoteca">Hipoteca:</label>
                <input
                  :value="hipoteca"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    hipoteca = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalVivienda"
                />
              </div>
              <div>
                <label for="alquiler">Alquiler:</label>
                <input
                  :value="alquiler"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    alquiler = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalVivienda"
                />
              </div>
              <div>
                <label for="seguroHogar">Seguro de Hogar:</label>
                <input
                  :value="seguroHogar"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    seguroHogar = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalVivienda"
                />
              </div>
              <div>
                <label for="televisionCable"
                  >Televisión por Cable/Internet:</label
                >
                <input
                  :value="televisionCable"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    televisionCable = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalVivienda"
                />
              </div>
              <div>
                <label for="AguaGasElectricidad">Agua/Gas/Electricidad:</label>
                <input
                  :value="agua"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    agua = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalVivienda"
                />
              </div>
              <div>
                <label for="TeléfonoYcelular">Teléfono y Celular:</label>
                <input
                  :value="telefono"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    telefono = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalVivienda"
                />
              </div>
            </div>
          </div>

          <!-- Sección de Gastos de Transporte -->
          <div v-if="section == 3" class="section-form">
            <h2>Gastos de Transporte</h2>
            <div>
              <div>
                <label for="pagoAuto">Pago del Auto:</label>
                <input
                  :value="pagoAuto"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    pagoAuto = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalTransporte"
                />
              </div>
              <div>
                <label for="transporte">Transporte:</label>
                <input
                  :value="transporte"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    transporte = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalTransporte"
                />
              </div>
              <div>
                <label for="gas">Gasolina:</label>
                <input
                  :value="gas"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    gas = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalTransporte"
                />
              </div>
              <div>
                <label for="seguroAuto">Seguro de Auto:</label>
                <input
                  :value="seguroAuto"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    seguroAuto = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalTransporte"
                />
              </div>
            </div>
          </div>

          <!-- Sección de Gastos educativos -->
          <div v-if="section == 4" class="section-form">
            <h2>Gastos educativos</h2>
            <div>
              <div>
                <label for="prestamoEstudiantil"
                  >Préstamos estudiantiles:</label
                >
                <input
                  :value="prestamoEstudiantil"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    prestamoEstudiantil = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalEducacion"
                />
              </div>
              <div>
                <label for="matriculaU">Matricula universitaria:</label>
                <input
                  :value="matriculaU"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    matriculaU = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalEducacion"
                />
              </div>
            </div>
          </div>

          <!-- Sección de Comida y Personal -->
          <div v-if="section == 5" class="section-form">
            <h2>Comida y Personal</h2>
            <div>
              <div>
                <label for="comestibles">Comestibles/Hogar:</label>
                <input
                  :value="comestibles"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    comestibles = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalComida"
                />
              </div>
              <div>
                <label for="ropa">Ropa:</label>
                <input
                  :value="ropa"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    ropa = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalComida"
                />
              </div>
              <div>
                <label for="entretenimiento">Entretenimiento:</label>
                <input
                  :value="entretenimiento"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    entretenimiento = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalComida"
                />
              </div>
              <div>
                <label for="medico">Medico:</label>
                <input
                  :value="medico"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    medico = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalComida"
                />
              </div>
              <div>
                <label for="suministrosMascotas"
                  >Suministros de mascotas:</label
                >
                <input
                  :value="suministrosMascotas"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    suministrosMascotas = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalComida"
                />
              </div>
              <div>
                <label for="otrosGastos">Otros gastos:</label>
                <input
                  :value="otrosGastos"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    otrosGastos = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalComida"
                />
              </div>
            </div>
          </div>

          <!-- Sección de Gastos de credito -->
          <div v-if="section == 6" class="section-form">
            <h2>Gastos de créditos</h2>
            <div>
              <div>
                <label for="tarjetas">Tarjetas de crédito:</label>
                <input
                  :value="tarjetas"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    tarjetas = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalCreditos"
                />
              </div>
              <div>
                <label for="creditoPersonal">Crédito personal:</label>
                <input
                  :value="creditoPersonal"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    creditoPersonal = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalCreditos"
                />
              </div>
            </div>
           </div>   
          <!-- Sección de Ahorros Mensuales-->
          <div v-if="section == 7" class="section-form">
            <h2>Ahorros Mensuales</h2>
            <div>
              <div>
                <label for="fondoEmergencia">Fondo de emergencia:</label>
                <input
                  :value="fondoEmergencia"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    fondoEmergencia = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalAhorros"
                />
              </div>
              <div>
                <label for="inversiones">Inversiones:</label>
                <input
                  :value="inversiones"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    inversiones = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalAhorros"
                />
              </div>
              <div>
                <label for="jubilacion">Jubilación:</label>
                <input
                  :value="jubilacion"
                  @input="
                    $event.target.value = $event.target.value
                      .replace(/[^\d]/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    jubilacion = $event.target.value;
                  "
                  type="text"
                  class="form-control"
                  @blur="getTotalAhorros"
                />
              </div>
            </div>
          </div>
        </div>
        <section v-if="section===8">
          <div v-if="totalDisponible>400000" class="section-asesoria">
            <h2>¿Necesitas ayuda para maximizar el crecimiento de tu capital disponible de {{ new Intl.NumberFormat().format(totalDisponible) }}?</h2>
            <p>Construimos contigo una hoja de ruta que alinea tu momento de vida, tus metas financieras y tu perfil de riesgo con un plan de inversión diversificado, consistente y automático</p>
            <a class="button-agendar" href="https://calendly.com/d/ywp-92m-mvg">Agendar asesoria</a>
          </div>
        </section>
        <div>
          <div class="contenedor-botones">
            <button 
              type="button"
              class="boton-inicio"
              v-if="section === 1 && mostrarBoton===true" 
              @click="ocultarBoton"> 
              Empezar
            </button>
            <form-registro v-if="formVisible" @close="ocultarFormulario" @ocultar="ocultarFormulario">
            </form-registro>
            <button
              type="button"
              class="boton"
              @click="anterior"
              v-if="section !== 1"
            >
              Anterior
            </button>
            <button
              type="button"
              :class="section !==7 ? 'boton' : section === 7 && totalDisponible<400000 ? 'boton-desabilitado' : 'boton'" 
              :disabled="disabledButton"
              v-if="section!==8 && mostrarBoton===false"
              @click="proximo"
            >
              Próximo
            </button>
          </div>
        </div>
      </form>

      <!-- Agregar la gráfica de torta -->
      <div>
        <table>
          <tbody>
            <tr>
              <td>Ingreso mensual</td>
              <td>${{ totalIngresos }}</td>
            </tr>
            <tr>
              <td>
                <div class="etiqueta" style="background-color: #f9c500"></div>
                Vivienda
              </td>
              <td>${{ gastosPorSeccion.vivienda }}</td>
            </tr>
            <tr>
              <td>
                <div class="etiqueta" style="background-color: #34b8ec"></div>
                Transporte
              </td>
              <td>${{ gastosPorSeccion.transporte }}</td>
            </tr>
            <tr>
              <td>
                <div class="etiqueta" style="background-color: #ff00b9"></div>
                Educativo
              </td>
              <td>${{ gastosPorSeccion.educacion }}</td>
            </tr>
            <tr>
              <td>
                <div class="etiqueta" style="background-color: #ff8b06"></div>
                Personal
              </td>
              <td>${{ gastosPorSeccion.comida }}</td>
            </tr>
             <tr>
              <td>
                <div class="etiqueta" style="background-color: #9DE031"></div>
                Créditos
              </td>
              <td>${{ gastosPorSeccion.creditos }}</td>
            </tr>
            <tr>
              <td>
                <div class="etiqueta" style="background-color: #9b59b6"></div>
                Ahorros
              </td>
              <td>${{ gastosPorSeccion.ahorros }}</td>
            </tr>
            <tr>
              <td>Gastos mensuales</td>
              <td>${{ totalGastos }}</td>
            </tr>
          </tbody>
        </table>
        <div class="chart-container">
          <canvas
            id="graficaTorta"
            style="min-width: 200px; max-height: 200px"
          ></canvas>
        </div>
        <div class="section-disponible" :class="buttonStatus">
          <p>Ingreso disponible</p>
          <span>${{ new Intl.NumberFormat().format(totalDisponible) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import FormRegistro from './FormRegistro.vue';

export default {
  components: { FormRegistro },
  data() {
    return {
      mostrarBoton: true,
      formVisible: false,
      totalDisponible: 0,
      section: 1,
      ingresos: "",
      otrosIngresos: "",
      hipoteca: "",
      alquiler: "",
      seguroHogar: "",
      televisionCable: "",
      agua: "",
      telefono: "",
      pagoAuto: "",
      transporte: "",
      seguroAuto: "",
      gas: "",
      prestamoEstudiantil: "",
      matriculaU: "",
      comestibles: "",
      ropa: "",
      entretenimiento: "",
      medico: "",
      suministrosMascotas: "",
      otrosGastos: "",
      tarjetas: "",
      creditoPersonal: "",
      fondoEmergencia: "",
      inversiones: "",
      jubilacion: "",
      grafica: null,
      totalIngresos: 0,
      totalGastos: 0,
      gastosPorSeccion: {
        vivienda: 0,
        transporte: 0,
        educacion: 0,
        comida: 0,
        creditos: 0,
        ahorros: 0,
      },
    };
  },
  mounted() {
    // Inicializar la gráfica al montar el componente
   /* const typeformDiv = document.createElement("div");
    typeformDiv.setAttribute("data-tf-live", "01HRAPW6X37E7HBD3SNHG5QMFJ");*/ 
    
    // Reemplaza con el ID de tu formulario de Typeform

    /*const typeformScript = document.createElement("script");
    typeformScript.src = "//embed.typeform.com/next/embed.js";*/ 
    // URL del script de incrustación de Typeform

    /*this.$refs.typeform.appendChild(typeformDiv);
    this.$refs.typeform.appendChild(typeformScript);*/
  },
  watch: {
    // Actualiza la gráfica cuando cambian los datos
    totalIngresos() {
      /*if (this.gastosPorSeccion.vivienda > 0) {
        this.getTotalVivienda();
      }
      if (this.gastosPorSeccion.transporte > 0) {
        this.getTotalTransporte();
      }
      if (this.gastosPorSeccion.educacion > 0) {
        this.getTotalEducacion();
      }
      if (this.gastosPorSeccion.comida > 0) {
        this.getTotalComida();
      }
      if (this.gastosPorSeccion.ahorros > 0) {
        this.getTotalAhorros();
      }*/
      this.actualizarGraficaTorta();
    },
    "gastosPorSeccion.vivienda": "actualizarGraficaTorta",
    "gastosPorSeccion.transporte": "actualizarGraficaTorta",
    "gastosPorSeccion.educacion": "actualizarGraficaTorta",
    "gastosPorSeccion.comida": "actualizarGraficaTorta",
    "gastosPorSeccion.creditos": "actualizarGraficaTorta",
    "gastosPorSeccion.ahorros": "actualizarGraficaTorta",
  },
  computed: {
    buttonStatus() {
      if (this.totalDisponible > 0) {
        return { positiveButton: true };
      } else if (this.totalDisponible < 0) {
        return { negativeButton: true };
      } else {
        return { sectionDisponible: true };
      }
    },
    disabledButton(){
          if(this.section === 7 && this.totalDisponible<400000){
            return true;
          }else{
            return false;
          }
       }
  },
  methods: {
    ocultarBoton() {
      this.mostrarBoton = false;
      this.formVisible = true;
    },
     ocultarFormulario(){
        this.formVisible = false;
     },
    anterior() {
      // Lógica para la acción del botón "Anterior"
      this.section -= 1;
    },
    proximo() {
      // Lógica para la acción del botón "Próximo
      this.section += 1;
    },
    getTotalIngresos(event) {
      if (!event.target.value && !this.totalIngresos) {
        return;
      }

      this.totalDisponible -= this.totalIngresos;

      this.totalIngresos =
        +this.ingresos.replace(/\./g, "") +
        +this.otrosIngresos.replace(/\./g, "");

      this.totalDisponible += this.totalIngresos;
    },
    getTotalVivienda(event) {
      if (!event.target.value && this.gastosPorSeccion.vivienda === 0 ) {
        return;
      }

      this.totalDisponible += this.gastosPorSeccion.vivienda;

      this.gastosPorSeccion.vivienda =
        +this.hipoteca.replace(/\./g, "") +
        +this.alquiler.replace(/\./g, "") +
        +this.seguroHogar.replace(/\./g, "") +
        +this.televisionCable.replace(/\./g, "") +
        +this.agua.replace(/\./g, "") +
        +this.telefono.replace(/\./g, "");

      this.totalDisponible -= this.gastosPorSeccion.vivienda;

      this.gastosPorSeccion.vivienda +
        this.gastosPorSeccion.transporte +
        this.gastosPorSeccion.educacion +
        this.gastosPorSeccion.comida + 
        this.gastosPorSeccion.creditos +
        this.gastosPorSeccion.ahorros;
    },
    getTotalTransporte(event) {
      if (!event.target.value && this.gastosPorSeccion.transporte === 0) {
        return;
      }

      this.totalDisponible += this.gastosPorSeccion.transporte;

      this.gastosPorSeccion.transporte =
        +this.pagoAuto.replace(/\./g, "") + +this.transporte.replace(/\./g, "") +
        +this.gas.replace(/\./g, "") +
        +this.seguroAuto.replace(/\./g, "");

      this.totalDisponible -= this.gastosPorSeccion.transporte;
      this.totalGastos =
        this.gastosPorSeccion.vivienda +
        this.gastosPorSeccion.transporte +
        this.gastosPorSeccion.educacion +
        this.gastosPorSeccion.comida + 
        this.gastosPorSeccion.creditos +
        this.gastosPorSeccion.ahorros;
    },
    getTotalEducacion(event) {
      if (!event.target.value && this.gastosPorSeccion.educacion === 0 ) {
        return;
      }

      this.totalDisponible += this.gastosPorSeccion.educacion;

      this.gastosPorSeccion.educacion =
        +this.prestamoEstudiantil.replace(/\./g, "") +
        +this.matriculaU.replace(/\./g, "");
      this.totalDisponible -= this.gastosPorSeccion.educacion;
      
      this.totalGastos =
        this.gastosPorSeccion.vivienda +
        this.gastosPorSeccion.transporte +
        this.gastosPorSeccion.educacion +
        this.gastosPorSeccion.comida + 
        this.gastosPorSeccion.creditos +
        this.gastosPorSeccion.ahorros;
    },
    getTotalComida(event) {
      if (!event.target.value && this.gastosPorSeccion.comida === 0) {
        return;
      }

      this.totalDisponible += this.gastosPorSeccion.comida;

      this.gastosPorSeccion.comida =
        +this.comestibles.replace(/\./g, "") +
        +this.ropa.replace(/\./g, "") +
        +this.entretenimiento.replace(/\./g, "") +
        +this.medico.replace(/\./g, "") +
        +this.suministrosMascotas.replace(/\./g, "") +
        +this.otrosGastos.replace(/\./g, "");
      this.totalDisponible -= this.gastosPorSeccion.comida;
      this.totalGastos =
         this.gastosPorSeccion.vivienda +
        this.gastosPorSeccion.transporte +
        this.gastosPorSeccion.educacion +
        this.gastosPorSeccion.comida + 
        this.gastosPorSeccion.creditos +
        this.gastosPorSeccion.ahorros;
    },
    getTotalCreditos(event) {
      if (!event.target.value && this.gastosPorSeccion.creditos === 0 ) {
        return;
      }

      this.totalDisponible += this.gastosPorSeccion.creditos;
      
      this.gastosPorSeccion.creditos =
        +this.tarjetas.replace(/\./g, "") +
        +this.creditoPersonal.replace(/\./g, "");
      this.totalDisponible -= this.gastosPorSeccion.creditos;
      this.totalGastos =
        this.gastosPorSeccion.vivienda +
        this.gastosPorSeccion.transporte +
        this.gastosPorSeccion.educacion +
        this.gastosPorSeccion.comida + 
        this.gastosPorSeccion.creditos +
        this.gastosPorSeccion.ahorros;

    },
    getTotalAhorros(event) {
      if (!event.target.value && this.gastosPorSeccion.ahorros === 0 ) {
        return;
      }

      this.totalDisponible += this.gastosPorSeccion.ahorros;

      this.gastosPorSeccion.ahorros =
        +this.fondoEmergencia.replace(/\./g, "") +
        +this.inversiones.replace(/\./g, "") +
        +this.jubilacion.replace(/\./g, "");
      this.totalDisponible -= this.gastosPorSeccion.ahorros;
      this.totalGastos =
         this.gastosPorSeccion.vivienda +
         this.gastosPorSeccion.transporte +
         this.gastosPorSeccion.educacion +
         this.gastosPorSeccion.comida + 
         this.gastosPorSeccion.creditos +
         this.gastosPorSeccion.ahorros;
    },
    actualizarGraficaTorta() {
      if (this.totalIngresos === 0) {
        return;
      }

      const datosGastos = {
        data: [
          (this.gastosPorSeccion.vivienda / this.totalIngresos) * 100,
          (this.gastosPorSeccion.transporte / this.totalIngresos) * 100,
          (this.gastosPorSeccion.educacion / this.totalIngresos) * 100,
          (this.gastosPorSeccion.comida / this.totalIngresos) * 100,
          (this.gastosPorSeccion.creditos / this.totalIngresos) * 100,
          (this.gastosPorSeccion.ahorros / this.totalIngresos) * 100,
        ],
        backgroundColor: [
          "#f9c500",
          "#34b8ec",
          "#ff00b9",
          "#ff8b06",
          "#9DE031",
          "#9b59b6",
        ],
        borderWidth: 1,
        hoverOffset: 8,
        cutout: '35%'
      };

      if (this.grafica) {
        this.grafica.destroy();
      }

      this.grafica = new Chart(document.querySelector("#graficaTorta"), {
        type: "doughnut",
        data: {
          /* labels: [`Vivienda $${this.gastosPorSeccion.vivienda}`, `Transporte $${this.gastosPorSeccion.transporte}`, `Educación $${this.gastosPorSeccion.educacion}`, `Alimentación $${this.gastosPorSeccion.comida}`, `Ahorros $${this.gastosPorSeccion.ahorros}`],*/
          datasets: [datosGastos],
        },
        options: {
          responsive: true,
          plugins: {
            title: {
            display: true
            }
          }
        }
      });
    }   
  }
};
</script>

<style scoped>
.presupuestador-menu {
  position: relative;
  max-width: 100%;
  padding: 1.25rem;
  color: #333333;
  margin-top: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.backdrop {
  height: 100%;
  width: 100%;
  opacity: 0.7;
  /*background-color: rgba(0, 0, 0, 0.75);*/
  z-index: 10;
}

form {
  width: 100%;
}

.menu-progreso {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.numero {
  width: 35px;
  height: 35px;
  border: 3px solid #ddd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin: 0;
  position: relative;
}

.numero-con-relleno {
  border: #3498db;
  background-color: #3498db;
  color: #fff;
}

.linea {
  flex: 1;
  height: 5px;
  background-color: #ddd;
  margin: 0;
  z-index: -1;
}

.linea-con-relleno {
  background-color: #3498db;
}

.budget {
  display: flex;
  flex-direction: column;
}

.section-form h2 {
  font-size: 28px;
  text-align: center;
  margin: 30px 0;
}

.section-form label {
  font-weight: bold;
  color: #555555;
  margin: 0 0 4px;
}

.section-form input {
  margin: 0 0 15px;
  padding: 1px 0 1px 9px;
}

.contenedor-botones {
  display: flex;
}

.boton-inicio{
    all: unset;
    font-family: Helvetica, Arial, sans-serif;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #0445AF;
    color: #fff;
    font-size: 18px;
    border-radius: 22px;
    padding: 0 30px;
    font-weight: bold;
    height: 45px;
    cursor: pointer;
    line-height: 45px;
    text-align: center;
    margin: 15px 0;
    text-decoration: none;
}

.boton {
  /*padding: 10px 20px;*/
  padding: 11px;
  /*margin: 0 10px;*/
  margin: 15px 0;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  outline: none;
}

.boton-desabilitado{
    padding: 11px;
    margin: 15px 0;
    font-size: 16px;
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    border: none;
    border-radius: 5px;
    outline: none;
    opacity: .65;
}

button:nth-child(1) {
  margin-left: 0;
  margin-right: 30px;
}

.boton:hover {
  background-color: #2980b9;
}

section {
   margin-bottom: 1.5rem;
}

section h2 {
  font-family: 'Poppins Bold';
  font-weight: 700;
  color: #000000;
  margin: 40px 0 20px;
  font-size: 30px;
}

.section-asesoria p{
  font-size: 16px;
  color: #000000;
  font-family: 'Open Sans';
}

.button-agendar{
  display: flex;
  place-content: center;
  padding: 5px 35px;
  border-radius: 25px;
  font-weight: bold;
  color: #ffffff;
  width: 250px;
  height: 35px;
  background: #F24B5A;
  text-decoration: none;
}

table {
  width: 100%;
  margin-top: 30px;
}

tr {
  display: grid;
  grid-template-columns: 7fr 3fr;
}

tr:nth-child(1),
tr:nth-child(7) {
  border-bottom: 1px solid #cfcfcf;
}

tr:nth-child(1),
tr:nth-child(8) {
  font-weight: bold;
}

td {
  padding: 3px 0;
}

td:nth-child(2) {
  justify-self: right;
}

.etiqueta {
  display: inline-block;
  margin-right: 5px;
  width: 12px;
  height: 12px;
}

.chart-container {
  display: grid;
  place-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid #cfcfcf;
  grid-template-columns: minmax(255px, auto);
  grid-template-rows: minmax(250px, auto);
  margin: 30px 0 0 0;
}

.section-disponible {
  display: flex;
  flex-direction: column;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: center;
  padding: 10px 25px;
  color: #fff;
  background: #354bc4;
  border-color: #354bc4;
  font-weight: bold;
}

.section-disponible p {
  font-size: 18.24px;
}

.section-disponible span {
  font-size: 25px;
}

.positiveButton {
  background: #2ecc71;
  border: #2ecc71;
}

/*.zeroButton{
    background: rgb(138, 133, 133);
    border: rgb(138, 133, 133);
}*/

.negativeButton {
  background: rgb(197, 37, 37);
  border: rgb(197, 37, 37);
}

.section-disponible p {
  margin: 0;
}

.section-disponible span {
  text-align: center;
}

@media (min-width: 31.25rem) {
  .presupuestador-menu {
    padding: 2.5rem;
  }
}

@media (min-width: 49.1rem) {
  .presupuestador-menu {
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    margin: 1.25rem;
  }

  .budget {
    flex-direction: row;
  }

  form {
    order: 2;
    padding-left: 40px;
  }

  .chart-container {
    order: 1;
  }

  #graficaTorta {
    max-width: 200px;
  }
}

@media (min-width: 62.5rem) {
  .presupuestador-menu {
    margin: 2rem;
  }

  .section-form div {
    display: grid;
    grid-template-columns: 45% 45%;
  }

  .section-form label {
    margin-right: 10px;
  }

  .section-form input {
    margin-right: 10px;
  }

  .section-asesoria p {
    font-size: 19px;
  }
   
}
</style>
<template>
  <div>
    <h1 class="text-center">Calculadora de presupuesto</h1>
    <p>
      Deja de preguntarte a dónde va todo el dinero que tanto te costó ganar. Ya sea que estés dando tus primeros pasos en el mundo de las finanzas personales o hayas estado trabajando en tu camino hacia la independencia financiera durante un tiempo, hacer un presupuesto puede ser una de las herramientas más efectivas para alcanzar tus metas. Si deseas experimentar con un plan de gastos, la calculadora de presupuesto gratuita de ABBA puede ayudate a comenzar.
      <br>
      <br>
      Este creador de presupuesto te guiará a través de las categorías presupuestarias clave y te ayudará a realizar un seguimiento de tus gastos mensuales. No importa si tu objetivo es saldar una deuda, comprar el auto de tus sueños o financiar una casa: nuestra calculadora puede ayudarte y no sólo te dirá a dónde se fue tu dinero en el pasado sino tambien, a dónde se irá en el futuro. Por eso te animamos a hacer dos presupuestos, uno para identificar patrones de gasto y otro para implementar mejoras en el manejo de tu dinero. 
    </p>  
  </div>
</template>

<style scoped>
div{
  color: #333333;
  padding: 0 20px;
  /*padding: 2rem 4rem;*/
}

h1 {
  font-size: 28px;
  font-weight: bold;
}

p {
  padding-top: 30px;
}

@media (min-width: 49.1rem) {
  h1 {
     font-size: 32px;
  }
}

@media (min-width: 62.5rem) {
  div{
    padding: 2rem 4rem;
  }
}
</style>
<template>
    <div @click="$emit('close')" class="backdrop"></div>
        <form @submit.prevent="submitForm">
            <svg @click="$emit('ocultar')" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg> 
            <h2>Déjanos tu mejor email para obtener consejos financieros</h2>
            <input id="username" name="username" class="form-control" v-model.trim="name" type="text" placeholder="Nombre"> 
            <input id="email" name="email" class="form-control" type="email" v-model.trim="email" placeholder="Tu mejor email">   
            <div>
                <button type="submit" class="boton-enviar">Enviar</button>
            </div>
        </form>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";


export default {
   data(){
     return {
        name: '',
        email: ''
     }
   },
   emits: ['close', 'ocultar'],
   methods:{
    async submitForm(){
        const firebaseConfig = {
            apiKey: "AIzaSyDigUuwPw1dLX3aPiCSY1YLg_rAmfUD02U",
            authDomain: "registros-abba.firebaseapp.com",
            projectId: "registros-abba",
            storageBucket: "registros-abba.appspot.com",
            messagingSenderId: "482718560402",
            appId: "1:482718560402:web:7ddbd30111fe715c89364b",
            measurementId: "G-9F6CG3JX06"
        };

        const firebaseApp = initializeApp(firebaseConfig);
        const db = getFirestore(firebaseApp);

        const colección = collection(db, "registros");
        const nuevoDocumento = await addDoc(colección, { name: this.name,
            email: this.email
        });

        this.$emit('close');
        
        console.log(nuevoDocumento);
    }
   }
}
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

form {
   display: grid;
   padding: 1rem;
   position: fixed;
   z-index: 100;
   top: 20vh;
   left:20px;
   right: 20px;
   margin: auto;
   background-color: #ffffff;
   border-radius: 6px;
}

svg {
   position: relative;
   top: -31px;
   justify-self: end;
   right: -28px;
   background-color: #ffffff;
   padding: 2px;
   border-radius: 13px;
}

form h2 {
    text-align: center;
    padding-right: 1rem;
    font-size: 20px;
    margin-bottom: 1.5rem;
}

form input {
    margin-bottom: 12px;
}

div{
    display: grid;
}

.boton-enviar{
    all: unset;
    font-family: Helvetica, Arial, sans-serif;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #0445AF;
    color: #fff;
    font-size: 18px;
    border-radius: 6px;
    padding: 0 30px;
    font-weight: bold;
    height: 45px;
    cursor: pointer;
    line-height: 45px;
    text-align: center;
    margin: 15px 0;
    text-decoration: none;
}

@media (min-width: 42.5rem) {
  form h2 {
    font-size: 24px;
  }

  div{
    display: flex;
    justify-content: center;
  }
}
</style>
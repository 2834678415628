<template>
  <div>
    <h2>Crea tu presupuesto en 3 sencillos pasos</h2>
    <br>
    <h3>Paso 1: ¿Cómo calcular tus ingresos mensuales?</h3>
    <p>
      ¿Listo para comenzar? Para utilizar nuestra calculadora de presupuesto
      mensual, primero debes calcular tus ingresos mensuales. Recuerda, esta es
      la cantidad que puedes gastar cada mes, así que asegúrate de usar tu
      ingreso neto, que es el dinero que te queda después de impuestos y
      deducciones por cosas como la seguridad social.
      <br>
      Para determinar qué ingresar en salario en la calculadora de presupuesto, sigue
      estos pasos: 
      <br>
      <ul>
        <li>Si te pagan una vez al mes, ingresa el valor después de las deducciones de cada pago</li>
        <li>Si te pagan dos veces al mes, suma el monto después de las deducciones de tus dos pagos e ingresa
           ese valor</li>
        <li>Si tus ingresos cambian de un mes a otro, suma el total de tus pagos mensuales de los últimos 3 meses y divide ese número entre 3 para obtener una estimación mensual de referencia.</li>
        <li> Si tienes ingresos adicionales, como un trabajo secundario, o recibes manutención infantil, u otro ingreso complementario, agrega esa cantidad mensual en otros ingresos. También puedes utilizar esta sección para agregar un segundo ingreso.</li>
      </ul> 
    </p>
    <h3>Paso 2: ¿Cómo determinar e ingresar los gastos mensuales?</h3>
      <p>Una vez que hayas calculado cuánto dinero ingresa cada mes después de impuestos, deberás calcular cuánto necesitas en tus gastos mensuales. Algunos de estos costos, como el alquiler y los pagos del automóvil, serán números específicos. Otros, como los gastos de comestibles y entretenimiento, serán estimaciones.</p>

      Empecemos:
      <br>
      <br>
      <h4>Gastos de vivienda</h4>
      <p>Hipoteca o alquiler: Si eres propietario y tus impuestos a la propiedad no están incluidos en el pago de su hipoteca, divide esos impuestos entre 12 y agrégalos a tu hipoteca; el objetivo es garantizar que tus impuestos a la propiedad estén cubiertos en tu presupuesto mensual.</p>
      
      <p>Seguro de hogar: Esta casilla es para el seguro de hogar o seguro de inquilino. Si tu seguro de hogar está incluido en el pago mensual de tu hipoteca, no te preocupes por agregarlo en esta sección; no necesitas contabilizar el pago dos veces.</p>

      <p>Agua/Gas/Electricidad:Las facturas de servicios públicos pueden ser difíciles de estimar: pueden variar mes a mes. Calcula un monto para tus gastos de servicios públicos en función de los últimos 3 meses de pagos</p>

      <p>Cable/TV/Internet: Si tienes un proveedor que se encarga de todos tus servicios tecnológicos, solo tendrás una factura, por lo que simplemente puedes ingresar el monto mensual en la calculadora de presupuesto aquí. Si obtienes servicio de TV e Internet de diferentes proveedores, suma esas facturas e ingresa el monto aquí.</p>

      <p>Teléfono/Celular: Si tienes un teléfono residencial y un teléfono celular, recuerda incluir ambos</p>

      <h4>Gastos de transporte</h4>
      <p>Pago del automóvil: Ya sea que estes pagando un préstamo para un Corvette 2023 nuevo o hayas alquilado un Fiat Panda usado, debes contabilizar tu pago mensual: ingresa el monto en la calculadora de presupuesto aquí. Si tienes más de un pago, simplemente súmalos.</p>

     <p>Seguro de automóvil: Ingresa aquí el pago mensual de tu seguro de automóvil. Si pagas la prima de tu seguro de automóvil cada 6 meses (o anualmente) en lugar de mensualmente, divide ese pago entre 6 o 12 para obtener el monto mensual. Ingresa ese número en la calculadora de presupuesto en esta línea.</p>

     <p>Combustible: Puede ser difícil calcular tu presupuesto mensual de gasolina. Piensa en la última vez que llenaste el tanque: ¿Cuánto pagaste? ¿Cuántas veces visitaste la bomba de gasolina en un periodo de cuatro semanas? MultipliCA los dos números e ingresa esa cantidad en esta línea.</p>

     <h4>Gastos educativos</h4>
     <p>
       Útiles escolares: Esta línea se puede usar para cualquier cosa, desde libros universitarios hasta cuadernos y lápices para tus hijos. Si esto no aplica para ti en absoluto, simplemente déjalo en blanco.
     </p>
     <p>
      Matrícula universitaria: Si pagas la matrícula por trimestre o semestre, suma el total de la matrícula y las tarifas anuales y divídelo por 12 para obtener una cantidad mensual. Sólo recuerda reservar los fondos cada mes hasta que venza la factura.
     </p>
     <p>
       Préstamos para estudiantes: Si tienes préstamos para estudiantes, usa esta línea para ingresar el monto total que pagas por esos préstamos cada mes. También puedes utilizar esta línea para presupuestar dinero adicional para esos préstamos, si deseas pagarlos más rápido.
     </p>

     <h4>Alimentos y gastos personales</h4>
     <p>Comestibles/Hogar: Estos 2 artículos se agrupan para cubrir alimentos y artículos para el hogar.    Por lo general, esto incluirá alimentos, artículos de cuidado y aseo personal y artículos para el hogar  como productos de limpieza. Calcula tus gastos mensuales en alimentos y suministros e ingresa ese número en esta sección.</p>
     <p>Entretenimiento: ¿Asistes habitualmente a conciertos? ¿El cine cuenta con tu presencia semanalmente? Ya sea que estés disfrutando de tu dosis de cultura en la ópera o cenando en tu restaurante japonés local, todos estos son gastos de entretenimiento.

     Tómate un momento para considerar todos tus gastos habituales en esta categoría. Eso incluye todo, desde salidas nocturnas en la ciudad hasta veladas con Netflix y Amazon Prime Video. Calcula el monto total e ingrésalo aquí.</p>
     <p>Artículos para mascotas: Ya sea que tengas un golden retriever o una boa de árbol esmeralda, estás gastando dinero cada mes para cuidar a tu mascota. Recuerda agregar un poco más a esta cantidad mensual para ahorrar para cosas como vacunas anuales, un terrario nuevo y elegante o tarifas de la perrera cuando se vaya de vacaciones. Agrega eso a lo que pagas por suministros mensuales, como comida para perros o arena para gatos, e ingrésalo aquí.</p>
     <p>Ropa: No olvides tener en cuenta un presupuesto de ropa cada mes; esto es especialmente útil si estás buscando las Jordan IV más raras, coleccionando prendas de abrigo italianas especializadas en Stone Island o haciendo un presupuesto para comprar ropa para tus hijos en crecimiento.

     Incluso si tienes dos o tres pares de los jeans desgastados más perfectos y no compras ropa nueva con mucha frecuencia, debes ingresar al menos una cantidad modesta para cubrir el derroche ocasional: todos lo hacemos.</p>
     <p>Médico: Esto incluye el costo mensual del seguro médico más facturas dentales y cualquier otra necesidad médica.</p>
      <p>Otros gastos: Entonces, ¿qué pasa con los gastos que no se incluyen en ninguna de estas categorías? ¿En qué tipo de cosas debería pensar para tus “otros” gastos? Echemos un vistazo a algunos ejemplos:</p>
      <ul>
        <li>Vacaciones</li>
        <li>Regalos de cumpleaños</li>
        <li>Cuidado de niños</li>
      </ul>
      <p>Esta es una categoría muy personal. Está diseñado para capturar cualquier cosa que aún no esté cubierta en tu presupuesto, por lo que será exclusivo para ti.

      Tómate unos minutos para pensar en todo lo que no esté cubierto por la calculadora de presupuesto mensual. Calcula un monto para esos gastos e ingrésalo aquí.
      </p>
      <h4>Paso 3: Cómo determinar e ingresar los ahorros mensuales</h4>
      <p>Fondo de Emergencia: Aquí es donde agregas tu aporte mensual a tu fondo de emergencia. Si decides incluir aquí cosas como el mantenimiento del hogar o del automóvil, recuerda incluir lo suficiente para cubrirlos.</p>
      <p>Jubilación: Si realizas contribuciones por separado cada mes a una cuenta de jubilación, ingrésalas aquí. Sin embargo, si tus contribuciones de jubilación salen de tu pago mensual, no es necesario volver a ingresarlo</p>
      <p>Inversiones: Utiliza esta línea para cualquier otro tipo de ahorro que puedas necesitar. Si realizas contribuciones a una cuenta de inversión individual puedes ingresarlas aquí.

      También puedes utilizar esta línea para contribuir a cualquier fondo especial en el que estés invirtiendo, como ahorrar para el pago inicial de una casa nueva.
      </p>
      <h4>Paso 4: ¿Cómo incorporar tu presupuesto a tu vida diaria?</h4>
      <p>
        Una vez que te hayas tomado el tiempo para pensar en tus gastos, tu vida diaria debería ajustarse bastante bien a los límites de tu presupuesto. Es fundamental controlar tu presupuesto y realizar un seguimiento de tus gastos durante algunos meses para ver dónde puede estar constantemente por encima o por debajo de tu presupuesto, y modificarlo según sea necesario.

        Una vez que tu presupuesto se ajuste a tus necesidades, manténlo y busca pequeñas formas de recompensarse por tus logros. Cómprate tu café favorito cada vez que contribuyas a tus ahorros. Recompénsate con tus tacos favoritos cuando te ciñas a tu presupuesto cada pocas semanas. Estas pequeñas cosas pueden significar mucho cuando se trata de desarrollar un hábito y mantener la disciplina.
      </p>
  </div>
</template>

<style scoped>
div {
  color: #333333;
  padding: 1.2rem 20px;
  /*padding: 2rem 4rem;*/
}

h2{
  font-size: 24px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}


h4 p{
  margin-bottom: 0;
}


@media (min-width: 49.1rem) {
  h2{
  font-size: 26px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
  font-size:20px;
}
}

@media (min-width: 62.5rem) {
  div{
    padding: 2rem 4rem;
  }
}
</style>